import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import OneIDVerif from "views/auth-views/OneIDVerif";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/admin`}
          component={lazy(() => import(`./admin`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/changePassword`}
          component={lazy(() => import(`./admin/adminprofile/ChangePassword`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/user-details`}
          component={lazy(() => import(`./user`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matched-users`}
          component={lazy(() => import(`./user/Matches`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/reports/users`}
          component={lazy(() => import(`./reports/users`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/view-user`}
          component={lazy(() => import(`./user/ViewSubAdmin`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/about-us`}
          component={lazy(() => import(`./settings/about-us`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/about-us-wizard`}
          component={lazy(() =>
            import(`../../components/forms/Wizard/AboutUsWizard`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/contact-us`}
          component={lazy(() => import(`./settings/contact-us`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/contact-form-wizard`}
          component={lazy(() =>
            import(`../../components/forms/Wizard/ContactUsWizard`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/terms-and-conditions`}
          component={lazy(() => import(`./settings/terms-and-conditions`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/terms-and-conditions-wizard`}
          component={lazy(() =>
            import(`../../components/forms/Wizard/TermsAndConditionsWizard`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/privacy-policy`}
          component={lazy(() => import(`./settings/privacy-policy`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/privacy-policy-wizard`}
          component={lazy(() =>
            import(`../../components/forms/Wizard/PrivacyPolicyWizard`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/apps`}
          component={lazy(() => import(`./apps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/components`}
          component={lazy(() => import(`./components`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/pages`}
          component={lazy(() => import(`./pages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/maps`}
          component={lazy(() => import(`./maps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/charts`}
          component={lazy(() => import(`./charts`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/docs`}
          component={lazy(() => import(`./docs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/promotion/discounts`}
          component={lazy(() => import(`./promotion/discounts`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/promotion/view-discount`}
          component={lazy(() => import(`./promotion/discounts/viewDiscount`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/promotion/add-discount`}
          component={lazy(() =>
            import(`../../components/forms/Wizard/DiscountWizard`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/reports/discounts`}
          component={lazy(() => import(`./reports/discounts`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/weekend`}
          component={lazy(() => import(`./user/WeekendPass.js`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audio`}
          component={lazy(() => import(`./user/AudioPass.js`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/chats`}
          component={lazy(() => import(`./user/ChatPass.js`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/reports/view-discount`}
          component={lazy(() => import(`./reports/discounts/viewDiscount`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/reports/payments`}
          component={lazy(() => import(`./reports/payments`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/user-details`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);

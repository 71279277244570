import { CURRENT_USER_EMAIL, URL } from "APIUtils";
import axios from "axios";
import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  AUTH_TOKEN,
} from "../constants/Auth";

// export const signIn = (user) => {
//   return {
//     type: SIGNIN,
//     payload: user,
//   };
// };

export const signIn =
  ({ email, password, setLoading, navigate }) =>
  async (dispatch) => {
    console.log("fheuhv", email, password);

    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    };

    try {
      const response = await fetch(`${URL}/admin/login`, settings);
      const data = await response.json();
      console.log("datdtatdtdta", data);
      if (data?.access_token != null) {
        localStorage.setItem(AUTH_TOKEN, data?.access_token);
        dispatch({
          type: "signIn",
          userDetails: data?.user,
          token: data?.access_token,
        });
        navigate();
        setLoading();
      } else {
        setLoading();
      }
    } catch (err) {
      setLoading();
      return err;
    }
  };

export const updateDiscount = (vals) => async (dispatch) => {
  let headersList = {
    Accept: "*/*",

    "Content-Type": "application/json",
  };

  let bodyContent = JSON.stringify({
    _id: "64be8cb25c99cf6596a7e5d3",
    type: vals?.type,
    expDate: vals?.expDate,
    startDate: vals?.startDate,
  });

  let reqOptions = {
    url: `${URL}/discounts/update`,
    method: "POST",
    headers: headersList,
    data: bodyContent,
  };

  let response = await axios.request(reqOptions);
  console.log(response.data);

  dispatch({
    type: "setDiscountsData",
    payload: response.data,
  });

  vals?.setLoading(false);
};

export const setAllUsers = (vals) => async (dispatch) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${URL}/admin/get-all-users/${vals?.page}/${vals?.sizePerPage}?${
      vals?.phone && "phone=" + vals?.phone
    }${vals?.email && "&email=" + vals?.email}${
      vals?.ageRange && "&ageRange=" + vals?.ageRange
    }${vals?.gender && "&gender=" + vals?.gender}`,
    headers: {},
  };

  console.log(config.url);

  await axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      vals?.setAllUsersData(response.data?.data);
      vals?.setTotalPages(response?.data?.totalPages);
      vals?.setCurrentPage(response?.data?.currentPage);
      vals?.setTotalItems(response?.data?.totalItems);

      console.log("RESPONSE ==>> ", response.data);

      let array = [];

      response.data.data.map((d, i) => {
        let temp = {
          Name: d.name,
          "Email ID": d.email,
          Age: d.age,
          Gender: d.gender,
          Location: d.location,
          "Phone No.": d.phone,
          Status:
            d.status == "SERIOUS"
              ? "Something Serious"
              : d.status == "FUN"
              ? "Something Casual"
              : d.status == "UNSURE" && "Search All",
          "Start Date": new Date(d.createdAt),
        };

        array.push(temp);
      });

      vals?.setCsvData(array);

      dispatch({
        type: "setAllUserData",
        payload: response.data,
      });
      vals?.setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      vals?.setLoading(false);
    });
};

export const setWeekendPass = (vals) => async (dispatch) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${URL}/weekendPassBought/get/${vals?.page}/${vals?.sizePerPage}?${
      vals?.filter && "filter=" + vals?.filter
    }`,
    headers: {},
  };

  await axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      vals?.setWeekendPassData(response.data?.data);
      vals?.setTotalPages(response?.data?.totalPages);
      vals?.setCurrentPage(response?.data?.currentPage);
      vals?.setTotalItems(response?.data?.totalItems);
      dispatch({
        type: "setWeekendPass",
        payload: response.data,
      });
      vals?.setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      vals?.setLoading(false);
    });
};

export const setAudioPass = (vals) => async (dispatch) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${URL}/audiosBought/get/${vals?.page}/${vals?.sizePerPage}?${
      vals?.filter && "filter=" + vals?.filter
    }`,
    headers: {},
  };

  await axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      vals?.setAudioPassData(response.data?.data);
      vals?.setTotalPages(response?.data?.totalPages);
      vals?.setCurrentPage(response?.data?.currentPage);
      vals?.setTotalItems(response?.data?.totalItems);
      dispatch({
        type: "setAudioPass",
        payload: response.data,
      });
      vals?.setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      vals?.setLoading(false);
    });
};

export const getChatPassData = (vals) => async (dispatch) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${URL}/chatsBought/get/${vals?.page}/${vals?.sizePerPage}?${
      vals?.filter && "filter=" + vals?.filter
    }`,
    headers: {},
  };

  await axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      vals?.setChatPassData(response.data?.data);
      vals?.setTotalPages(response?.data?.totalPages);
      vals?.setCurrentPage(response?.data?.currentPage);
      vals?.setTotalItems(response?.data?.totalItems);
      dispatch({
        type: "setChatPassData",
        payload: response.data,
      });
      vals?.setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      vals?.setLoading(false);
    });
};

export const getDiscountsData = (vals) => async (dispatch) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${URL}/discounts/all/${vals?.page}/${vals?.sizePerPage}`,
    headers: {},
  };

  await axios
    .request(config)
    .then((response) => {
      console.log("tetetete", response.data);
      vals?.setDiscountsData(response.data?.data);
      vals?.setTotalPages(response?.data?.totalPages);
      vals?.setCurrentPage(response?.data?.currentPage);
      vals?.setTotalItems(response?.data?.totalItems);
      dispatch({
        type: "setDiscountsData",
        payload: response.data?.data[0],
      });
      vals?.setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      vals?.setLoading(false);
    });
};

export const addDiscounts = (vals) => async (dispatch) => {
  var options = {
    method: "POST",
    url: `${URL}/discounts/create`,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
    data: {
      code: vals?.code,
      type: vals?.type,
      hasLimit: vals?.hasLimit,
      useRemain: vals?.useRemain,
      expDate: vals?.expDate,
      startDate: vals?.startDate,
    },
  };

  await axios
    .request(options)
    .then(function (response) {
      console.log("reposnse discount", response.data);
      vals?.setLoading();
    })
    .catch(function (error) {
      console.error(error);
    });
};

export const getAudioPassData = (vals) => async (dispatch) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${URL}/audiosBought/get`,
    headers: {},
  };

  await axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));

      let arr = [];

      response.data?.data.map((item, i) => {
        let temp = {
          stripeId: item.stripeId,
          emailId: item.userBought?.email,
          userBoughtId: item.userBought?._id,
          userAudioId: item.userWithAudio?._id,
          audioUrl: item.userWithAudio?.profile_audio,
        };

        arr.push(temp);
      });

      vals?.setAudioPassData(arr);

      dispatch({
        type: "set",
        audioPassData: arr,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getWeekendPassData = (vals) => async (dispatch) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${URL}/weekendPassBought/get`,
    headers: {},
  };

  await axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));

      let arr = [];

      response.data?.data.map((item, i) => {
        let temp = {
          stripeId: item.stripeId,
          emailId: item.userBought?.email,
          userId: item.userBought?._id,
          userPurchaseDate: item.userPurchaseDate,
          validFrom: item.validFrom,
          validTo: item.validTo,
          expired: item.expired,
        };

        arr.push(temp);
      });

      vals?.setWeekendPassData(arr);

      dispatch({
        type: "set",
        weekendPassData: arr,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getAdminData = (vals) => async (dispatch, state) => {
  return;
  const settings = {
    method: "POST",
    headers: {
      Authorization: state()?.token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: localStorage.getItem(CURRENT_USER_EMAIL),
    }),
  };

  await fetch(`${URL}/admin/get-admin-details`, settings)
    .then((response) => response.json())
    .then((data) => {
      console.log("adimn data", data);
      // dispatch({
      //   type: "set",
      //   allAdminData: data,
      // });
      vals?.setSubAdmins([data]);
    })
    .catch((error) => {
      console.error("admin data", error);
    });
};

export const getSubadminAdminData = (vals) => async (dispatch, state) => {
  const settings = {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem(AUTH_TOKEN),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  fetch(`${URL}/admin/get-all-subadmins`, settings)
    .then((response) => response.json())
    .then(function (subadmins) {
      // setSubAdmins(subadmins);

      if (vals?.user.type === "subadmin") {
        const filteredData = subadmins.filter((value) =>
          value.email.toLowerCase().includes(vals?.user.email.toLowerCase())
        );
        dispatch({
          type: "set",
          subadminsData: subadmins,
        });
        vals?.setSubAdmins(filteredData);
      } else {
        dispatch({
          type: "set",
          subadminsData: subadmins,
        });
        vals?.setSubAdmins(subadmins);
      }
    });
};
export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token,
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  };
};

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE,
  };
};

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token,
  };
};

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK,
  };
};

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK,
} from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
} from "../actions/Auth";

import FirebaseService from "services/FirebaseService";

import {
  URL,
  CURRENT_USER_EMAIL,
  CURRENT_USER_TYPE,
  PERMISSIONS,
} from "APIUtils";

// export function* signInWithFBEmail() {
//   yield takeEvery(SIGNIN, function* ({ payload }) {
//     const { email, password } = payload;
//     try {
//       const user = yield call(
//         FirebaseService.signInEmailRequest,
//         email,
//         password
//       );
//       if (user.message) {
//         yield put(showAuthMessage(user.message));
//       } else {
//         localStorage.setItem(AUTH_TOKEN, user.user.uid);
//         yield put(authenticated(user.user.uid));
//       }
//     } catch (err) {
//       yield put(showAuthMessage(err));
//     }
//   });
// }

const signIn = async (email, password) => {
  console.log("fheuhv", email, password);
  return;
  const settings = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  };
  try {
    try {
      const response = await fetch(`${URL}/admin/login`, settings);
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  } catch (error) {
    console.log(error);
  }
};

export function* signInWithEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const data = yield call(signIn, email, password);
      console.log(data);

      if (data) {
        localStorage.setItem(AUTH_TOKEN, data.access_token);
        localStorage.setItem(CURRENT_USER_EMAIL, data.user.email);
        localStorage.setItem(CURRENT_USER_TYPE, data.user.type);
        if (data.user.type === "subadmin") {
          localStorage.setItem(
            PERMISSIONS,
            JSON.stringify(data.user.permissions)
          );
        }
        yield put(authenticated(data.access_token));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest);
      if (signOutUser === undefined) {
        localStorage.clear();
        yield put(signOutSuccess(signOutUser));
      } else {
        yield put(showAuthMessage(signOutUser.message));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

// export function* signUpWithFBEmail() {
//   yield takeEvery(SIGNUP, function* ({ payload }) {
//     const { email, password } = payload;
//     try {
//       const user = yield call(
//         FirebaseService.signUpEmailRequest,
//         email,
//         password
//       );
//       if (user.message) {
//         yield put(showAuthMessage(user.message));
//       } else {
//         localStorage.setItem(AUTH_TOKEN, user.user.uid);
//         yield put(signUpSuccess(user.user.uid));
//       }
//     } catch (error) {
//       yield put(showAuthMessage(error));
//     }
//   });
// }

const signUp = async (email, password, name, phone) => {
  const settings = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name,
      email: email,
      password: password,
      phone: phone,
      type: "admin",
    }),
  };
  try {
    try {
      const response = await fetch(`${URL}/admin/sign-up`, settings);
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  } catch (error) {
    console.log(error);
  }
};

export function* signUpWithEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password, name, phone } = payload;
    try {
      const data = yield call(signUp, email, password, name, phone);

      if (data) {
        console.log(data.id);
        localStorage.setItem(AUTH_TOKEN, data.access_token);
        localStorage.setItem(CURRENT_USER_EMAIL, data.user.email);
        localStorage.setItem(CURRENT_USER_TYPE, data.user.type);
        if (data.user.type === "subadmin") {
          localStorage.setItem(
            PERMISSIONS,
            JSON.stringify(data.user.permissions)
          );
        }
        yield put(signUpSuccess(data.access_token));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

// export function* signInWithFBGoogle() {
//   yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
//     try {
//       const user = yield call(FirebaseService.signInGoogleRequest);
//       if (user.message) {
//         yield put(showAuthMessage(user.message));
//       } else {
//         localStorage.setItem(AUTH_TOKEN, user.user.uid);
//         yield put(signInWithGoogleAuthenticated(user.user.uid));
//       }
//     } catch (error) {
//       yield put(showAuthMessage(error));
//     }
//   });
// }

// export function* signInWithFacebook() {
//   yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
//     try {
//       const user = yield call(FirebaseService.signInFacebookRequest);
//       if (user.message) {
//         yield put(showAuthMessage(user.message));
//       } else {
//         localStorage.setItem(AUTH_TOKEN, user.user.uid);
//         yield put(signInWithFacebookAuthenticated(user.user.uid));
//       }
//     } catch (error) {
//       yield put(showAuthMessage(error));
//     }
//   });
// }

export default function* rootSaga() {
  yield all([
    fork(signInWithEmail),
    fork(signOut),
    fork(signUpWithEmail),
    // fork(signInWithFBGoogle),
    // fork(signInWithFacebook),
  ]);
}
